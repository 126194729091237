<template>
  <div class="mx-5 my-5">
    <v-row>
      <v-col md="12">
        <v-switch @change="toggleInvoiceLock" v-if="!isNew" v-model="is_locked" color="green"
          :label="$t('Invoice Lock')" :dark="$store.state.isDarkMode"></v-switch>
      </v-col>
      <v-col md="4">
        <v-text-field :label="$t('Invoice Number')" outlined dense v-model="invoice.serial"></v-text-field>
      </v-col>
      <v-col md="4">
        <AutoCompleteField outlined v-model="invoice.old_inventory_id" label="المستودع القديم"
          endPoint="/inventories/auto-complete" returnObject />
      </v-col>
      <v-col md="4">
        <AutoCompleteField outlined v-model="invoice.new_inventory_id" label="المستودع الجديد"
          endPoint="/inventories/auto-complete" returnObject />
      </v-col>
      <v-col md="4" class="py-0">
        <v-menu ref="menu1" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
          max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :label="$t('date')" :dark="$store.state.isDarkMode" outlined dense persistent-hint prepend-icon="mdi-calendar" v-bind="attrs"
              v-on="on" v-model="invoiceDate"></v-text-field>
          </template>
          <v-date-picker v-model="invoiceDate" no-title @input="dateMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="4" class="py-0">
        <v-menu ref="timeMenuRef" v-model="timeMenu" :close-on-content-click="false" :nudge-right="40"
          :return-value.sync="invoiceTime" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="invoiceTime" :label="$t('Time')" :dark="$store.state.isDarkMode" prepend-icon="mdi-clock-time-four-outline" readonly
              outlined dense v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="timeMenu" v-model="invoiceTime" full-width
            @click:minute="$refs.timeMenuRef.save(invoiceTime)"></v-time-picker>
        </v-menu>
      </v-col>

      <v-col md="4" class="py-0">
        <v-text-field :label="$t('notes')" v-model="invoice.notes" outlined dense></v-text-field>
      </v-col>
      <v-col md="12" class="py-0">
        <table id="tableId">
          <tr>
            <th>{{ $t('Material') }}</th>
            <th>{{ $t('Type') }}</th>
            <th>{{ $t('quantity') }}</th>
            <th></th>
          </tr>
          <tr v-for="(material, ix) in invoice.materials" :key="ix">
            <td>
              <AutoCompleteField label="" v-model="material.material_id" endPoint="/inventories/materials/auto-complete"
                returnObject @input="ToNextInput" />
            </td>
            <td>
              {{
                material.material_id
                ? material.material_id.type == "SERVICE"
                  ? "خدمية"
                  : "مستودع"
                : ""
              }}
            </td>
            <td>
              <v-text-field full-width dense hide-details flat type="number" color="#757575"
                @keydown.enter="addNewMaterial" v-model.number="material.quantity"></v-text-field>
            </td>
            <td>
              <v-icon @click="deleteRow(ix)" color="red"
                style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
            </td>
          </tr>
        </table>
      </v-col>

      <v-col md="4">
        <v-btn @click="createNewInvoice" color="blue" block :loading="submitting" :disabled="!isNew && is_locked">{{ isNew
          ?  $t('add')  : 'تعديل' }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import AutoCompleteField from "./AutoCompleteField.vue";
export default {
  components: { AutoCompleteField },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      is_locked: false,
      invoice: {
        invoice_type_id: this.$route.query.invoice_id,
        serial: null,
        new_inventory_id: null,
        old_inventory_id: null,
        notes: null,
        materials: [],
      },
      fetchingMetadata: false,
      submitting: false,
      dateMenu: false,
      timeMenu: false,
      timeMenuRef: null,
      invoiceDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      invoiceTime: `${String(new Date().getHours()).length == 1
          ? `0${new Date().getHours()}`
          : new Date().getHours()
        }:${String(new Date().getMinutes()).length == 1
          ? `0${new Date().getMinutes()}`
          : new Date().getMinutes()
        }`,
    };
  },
  methods: {
    async toggleInvoiceLock() {
      await axios.patch(
        `/inventories/invoices/${this.$route.params.id}/toggle-lock`,
        {
          is_locked: this.is_locked,
        }
      );
    },
    deleteRow(index) {
      if (this.invoice.materials.length == 1) return;
      this.invoice.materials.splice(index, 1);
    },
    addNewMaterial(focus = true) {
      this.invoice.materials.push({
        material_id: null,
        type: null,
        quantity: null,
      });
    },
    ToNextInput() {
      const inputs = Array.prototype.slice.call(
        document.querySelectorAll("table input:not([type=hidden])")
      );
      const currInput = document.activeElement;
      const currInputIndex = inputs.indexOf(currInput);
      const nextinputIndex = currInputIndex + 1;
      const input = inputs[nextinputIndex];
      input.focus();
    },
    async createNewInvoice() {
      try {
        this.submitting = true;
        if (this.isNew) {
          await axios.post("/inventories/invoices", {
            ...this.invoice,
            date: `${this.invoiceDate} ${this.invoiceTime}`,
            new_inventory_id: this.invoice.new_inventory_id?.id,
            old_inventory_id: this.invoice.old_inventory_id?.id,
            materials: this.invoice.materials.map((mat) => ({
              ...mat,
              material_id: mat.material_id?.id,
              type: mat.material_id.type,
            })),
          });

        } else {
          await axios.put(`/inventories/invoices/${this.$route.params.id}`, {
            ...this.invoice,
            date: `${this.invoiceDate} ${this.invoiceTime}`,
            new_inventory_id: this.invoice.new_inventory_id?.id,
            old_inventory_id: this.invoice.old_inventory_id?.id,
            materials: this.invoice.materials.map((mat) => ({
              ...mat,
              material_id: mat.material_id?.id,
              type: mat.material_id.type,
            })),
          });

        }
        this.$router.push("/invoice-list");
      } finally {
        this.submitting = false;
      }
    },
    async getInvoiceTypeMetadata() {
      try {
        this.fetchingMetadata = true;
        const result = await axios.get(
          `/inventories/invoices/invoice-type/${this.$route.query.invoice_id}`
        );
        this.invoiceMetadata = result.data.data;
        this.invoice.serial = this.invoiceMetadata.next_serial;
      } finally {
        this.fetchingMetadata = false;
      }
    },
  },
  async created() {
    // this.getInvoiceTypeMetadata();

    if (this.isNew) {
      this.getInvoiceTypeMetadata();
      this.invoice.materials.push({
        material_id: null,
        type: null,
        quantity: null,
      });
    } else {
      const result = await axios.get(
        `/inventories/invoices/${this.$route.params.id}`
      );
      const dataTemp = result.data.data;
      const dateSplit = dataTemp.date.split(" ");
      this.is_locked = dataTemp.is_locked;
      this.invoiceDate = dateSplit[0];
      this.invoiceTime = dateSplit[1];
      this.invoice.serial = dataTemp.serial;
      this.invoice.notes = dataTemp.notes;

      this.invoice.materials = dataTemp.invoice_materials.map((el) => ({
        ...el,
        material_id: el.material,
      }));

      this.invoice.old_inventory_id = dataTemp.transportation_metadata.old_inventory
      this.invoice.new_inventory_id = dataTemp.transportation_metadata.new_inventory
    }
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
  /* table-layout: fixed; */
}

td,
th {
  padding: 5px 15px;
}

table,
th,
td {
  border: 1px solid #000;
}

.columnSelector:hover {
  border-right: 2px solid blue;
}
</style>

<template>
  <v-container>
  <v-autocomplete
    :label="label"
    dense
    color="#757575"
    item-text="name"
    item-value="id"
    full-width
    hide-details
    
    :placeholder="placeholder"
    :outlined="outlined"
    flat
    :items="itemsVal"
    :clearable="clearable"
    :value="value"
    :return-object="returnObject"
    :loading="loading"
    v-debounce="autoCompleteFn"
    @keydown.enter="$emit('keydown')"
    @input="$emit('input', $event)"
  ></v-autocomplete>
</v-container>
</template>

<script>
import axios from "axios";
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      type: [Object, String, Number],
    },
    endPoint: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemsVal: [],
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        if (v && typeof v == "object") {
          this.itemsVal = [{ ...v }];
        }
      },
    },
  },
  methods: {
    async autoCompleteFn(value) {
      try {
        if (!value) return;
        this.loading = true;
        const searchResult = await axios.get(this.endPoint, {
          params: {
            name: value,
            query: value,
          },
        });
        if(this.endPoint=='/user/auto-complete') this.itemsVal = searchResult.data.users; 
        else if(this.endPoint=='/test') this.itemsVal = searchResult.data.tests; 
        else this.itemsVal = searchResult.data.data;
        
      } catch (err) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
